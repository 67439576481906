import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Loader from "../../../loader/Loader";
import AxiosController from "../../../authentication/AxiosController";
import { Button, Card, Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Tabs } from "@mantine/core";

const MarketerOverview = () => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState({});
  const [marketerId, setMarketerId] = useState("");

  const get_marketer_overview = () => {
    setLoading(true);
    AxiosController.get(
      `/apiv2/${slug}/${lang}/marketers-reports/marketer/${marketerId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        setLoading(false);
        setAllData(res?.data || {});
      })
      .catch(() => {
        setLoading(false);
        setAllData({});
      });
  };

  return (
    <div className="flex-grow-1 stats-component" dir={box_head_dir}>
      <Helmet>
        <title>{_("panel.marketerOverview")}</title>
      </Helmet>
      <div className="reports-container my-3 d-flex flex-column gap-2 px-2 position-relative">
        <Loader active={loading} />

        <div className="reports-header d-flex flex-column flex-md-row py-2 justify-content-between flex-wrap">
          <h3 className="fs-2 mb-4" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.marketerOverview")}
          </h3>
        </div>
        <div className="d-flex gap-3">
          <input
            type="text"
            className="form-control mb-4"
            placeholder={_("panel.themarketer")}
            onChange={(e) => setMarketerId(e.target.value)}
          />
          <Button
            className="fs-5 px-5 w-25 mb-4"
            style={{
              backgroundColor: "rgb(89, 198, 201)",
              border: "none",
              borderRadius: "7px",
            }}
            onClick={get_marketer_overview}
          >
            {_("panel.search")}
          </Button>
        </div>
        <div className="row m-1 pb-3">
          <Card className="p-3">
            <div className="row g-0">
              <div className="col-lg-4">
                <Card.Img
                  src={
                    allData?.user?.photo ||
                    "https://res.cloudinary.com/ecombo-network/image/upload/f_auto/v1637605387/Ecombo/blank-image_yr3ead.jpg"
                  }
                  alt="user? Photo"
                  className="img-fluid rounded-start"
                  style={{ objectFit: "cover", height: "100%" }}
                />
              </div>
              <div className="col-lg-8 gy-4">
                <Card.Body>
                  <Card.Title className="fw-bold fs-4 mt-2 mb-2">
                    {allData?.user?.first_name} {allData?.user?.last_name}
                  </Card.Title>
                  <Card.Subtitle className="mb-2 mt-2 text-muted">
                    {allData?.user?.email}
                  </Card.Subtitle>
                  <Card.Text className=" d-flex flex-column">
                    <div>
                      <strong>{_("panel.Telephone")} : </strong>
                      {allData?.user?.phone_number}
                    </div>
                    <br />
                    <div>
                      <strong>{_("panel.birth")} :</strong>
                      {allData?.user?.birthdate
                        ? new Date(
                            allData?.user?.birthdate
                          ).toLocaleDateString()
                        : "N/A"}
                    </div>
                    <br />
                    <div>
                      <strong>{_("panel.country")} : </strong>
                      {allData?.user?.country || "N/A"}
                    </div>
                    <br />
                    <div>
                      <strong>{_("panel.status")} : </strong>
                      {allData?.user?.is_active ? "Active" : "Inactive"}
                    </div>
                    <br />
                    <div>
                      <strong>{_("panel.lastLogin")} :</strong>
                      {allData?.user?.last_login
                        ? new Date(allData?.user?.last_login).toLocaleString()
                        : "N/A"}
                    </div>
                  </Card.Text>
                  <Button
                    className="mt-2 mb-2"
                    variant="primary"
                    href={allData?.user?.fb_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {_("panel.facebook")}
                  </Button>
                </Card.Body>
              </div>
            </div>
          </Card>
        </div>
        <Tabs defaultValue="products" className="w-100">
          <Tabs.List>
            <Tabs.Tab value="products" className="fs-5" color="rgb(89, 198, 201)">
              {_("panel.products")}
            </Tabs.Tab>
            <Tabs.Tab value="cities" className="fs-5" color="rgb(89, 198, 201)">
              {_("home.cities")}
            </Tabs.Tab>

            <Tabs.Tab value="statusDetails" className="fs-5" color="rgb(89, 198, 201)">
              {_("panel.statusDetails")}
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="products">
            <div 
              style={{ position: "relative", overflowX: "auto" }}
            >
              <Table
                responsive
                dir={box_head_dir}
                className="reports-table text-nowrap text-center"
              >
                <thead>
                  <tr className="text-center">
                    <th>{_("panel.productTitle")}</th>
                    <th>{_("panel.totalorders")}</th>
                  </tr>
                </thead>
                <tbody>
                  {allData &&
                    allData.ordersDetails_products &&
                    allData.ordersDetails_products.map((product) => (
                      <tr key={product.product_id}>
                        <td>
                          <span className="fw-bold">
                            {" "}
                            {product.product_id} #
                          </span>
                          <br />
                          {product.product.title}
                        </td>
                        <td>{product.orders_number}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </Tabs.Panel>
          <Tabs.Panel value="cities">
            <div 
              style={{ position: "relative", overflowX: "auto" }}
            >
              <Table
                responsive
                dir={box_head_dir}
                className="reports-table text-nowrap text-center"
              >
                <thead>
                  <tr className="text-center">
                    <th>{_("home.provinces")}</th>
                    <th>{_("home.productsNumber")}</th>
                  </tr>
                </thead>
                <tbody>
                  {allData &&
                    allData.orders_govs &&
                    allData.orders_govs.map((gov) => (
                      <tr key={gov.gov_id}>
                        <td>
                          <span className="fw-bold"> # {gov.gov_id}</span>{" "}
                          <br />
                          {gov.government.arabic_name}
                        </td>
                        <td>{gov.total_orders}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </Tabs.Panel>
          <Tabs.Panel value="statusDetails">
            <div 
              style={{ position: "relative", overflowX: "auto" }}
            >
              <Table
                responsive
                dir={box_head_dir}
                className="reports-table text-nowrap text-center"
              >
                {" "}
                <thead>
                  <tr className="text-center">
                    <th>{_("panel.status")}</th>
                    <th>{_("home.productsNumber")}</th>
                  </tr>
                </thead>
                <tbody>
                  {allData &&
                    allData.orders_status &&
                    allData.orders_status.map((status) => (
                      <tr key={status.status}>
                        <td>{status.status}</td>
                        <td>{status.total_orders}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </Tabs.Panel>
        </Tabs>
      </div>
    </div>
  );
};

export default MarketerOverview;
