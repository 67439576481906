import React, { useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dateFormat from "dateformat";
import IconsGen from "../../../icons_generator/IconsGen";

const AboutShippingTable = ({ data }) => {
  const { lang } = useParams();
  const [_] = useTranslation("global");

  return (
    <div
      style={{ borderRadius: "40px" }}
      className={`table-responsive border my-3 d-flex ${
        lang === "en" ? "flex-row" : "flex-row-reversed"
      } align-items-center`}
    >
      <table className="table table-borderless" htmlFor="flexCheckDefault">
        <thead>
          <tr style={{ textAlign: "center" }}>
            <th>#</th>
            <th>{_("panel.date")}</th>
            <th>{_("panel.Courier")} <br/> {_('panel.totalorders')}</th>
            <th>{_("panel.employee")}</th>
            <th>{_("panel.affiliatesBalance")}</th>
            <th>{_("panel.sellersBalance")}</th>
            <th>{_("panel.systemBalance")}</th>
            <th>{_("panel.reportTotal")}</th>
            <th>{_("panel.downloadExcel")}</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 &&
            data.map((ele, index) => {
              let employee = "_";
              if (ele?.first_name !== null) {
                employee =
                  "#" +
                  ele?.user_id +
                  " " +
                  ele?.first_name +
                  " " +
                  ele?.last_name;
              }
              return (
                <tr key={ele.id}>
                  <td>{index + 1}</td>
                  <td>
                    {dateFormat(ele["created_at"], "dd/mm/yyyy ")} <br />
                    {dateFormat(ele["created_at"], " h:MM TT")}
                  </td>
                  <td>
                    {ele?.["courier_name"]}
                    <br /> {Number(ele?.["orders_count"]).toLocaleString()} 
                  </td>
                  <td> {employee}</td>
                  <td>
                    {parseInt(ele?.["affiliates_balance"]).toLocaleString()}
                  </td>
                  <td>{parseInt(ele?.["sellers_balance"]).toLocaleString()}</td>
                  <td>{parseInt(ele?.["system_balance"]).toLocaleString()}</td>

                  <td>
                    {(
                      parseInt(ele?.["system_balance"]) +
                      parseInt(ele?.["affiliates_balance"]) +
                      parseInt(ele?.["sellers_balance"])
                    ).toLocaleString()}
                  </td>
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() => (window.location.href = ele["file"])}
                  >
                    {" "}
                    <IconsGen icon_name={"excel"} />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default AboutShippingTable;
