import React, { useEffect, useState } from "react";
import "./style.css";
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AxiosController from "../../../../authentication/AxiosController";
import Loader from "../../../../loader/Loader";
import { Pagination } from "react-bootstrap";
import PaymentRequestsTableRow from "./PaymentRequestsTableRow";
import IconsGen from "../../../../icons_generator/IconsGen";
import { Helmet } from "react-helmet";

const fetchPaymentRequests = (
  slug,
  lang,
  token,
  handleOrdesData,
  page,
  handleLoader
) => {
  AxiosController.get(`api/${slug}/${lang}/products-pickup/`, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      handleOrdesData(res);
      handleLoader(false);
    })
    .catch((e) => {});
};
const fetchPickUpNumber = (slug, lang, token, handleOrdesData) => {
  AxiosController.get(`api/${slug}/${lang}/orders/counter/ready-confirmed`, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      handleOrdesData(res.data);
    })
    .catch((e) => {});
};

const PickupReport = () => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const token = localStorage.getItem("token");
  const [paymentRequests, handlePaymentRequests] = useState();
  const [counter, setCounter] = useState([]);
  const [page, handlePage] = useState(1);
  const [loading, handleLoader] = useState(false);

  useEffect(() => {
    handleLoader(true);
    fetchPaymentRequests(
      slug,
      lang,
      token,
      handlePaymentRequests,
      page,
      handleLoader
    );
    fetchPickUpNumber(slug, lang, token, setCounter);
  }, [token, slug, lang, page, handleLoader]);
  return (
    <div
      className="d-flex flex-column flex-lg-column my-2 gap-3 position-relative"
      style={{ width: "100%" }}
    >
      {" "}
      <Helmet>
        <title>{_("panel.pickupReport")}</title>
      </Helmet>
      <Loader active={loading} />
      <div
        className="tables-container d-flex flex-column px-2"
        style={{ position: "relative", flexGrow: 3 }}
      >
        <div
          className={`header d-flex ${
            lang === "en" ? "flex-row" : "flex-row"
          } gap-3 align-items-center justify-content-between`}
        >
          <h3 className="fs-1" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.pickupReport")}
          </h3>
        </div>
        <div className="footer d-flex flex-column flex-grow-1">
          {paymentRequests ? (
            <PaymentRequestsTableRow
              payments_list={paymentRequests}
              handleLoader={handleLoader}
              counter={counter}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default PickupReport;
