import React, { useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DateRangePickerComponent from "../../../date/DateRangePickerComponent";
import AxiosController from "../../../authentication/AxiosController";
import { MultiSelect } from "primereact/multiselect";
import { format } from "date-fns";

const Search = () => {
  const [_] = useTranslation("global");
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const location = useLocation();
  const Navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);
  const [courier_id, handleCourier_id] = useState("");
  const [product_id, handleProduct_id] = useState("");
  const [userId, handleUserId] = useState("");
  const [startDate, handleStartDate] = useState("");
  const [endDate, handleEndDate] = useState("");
  const cours = [];
  const [couriers, setCouriers] = useState([]);
  const [selectedCouriers, setSelectedCouriers] = useState([]);

  couriers.forEach((gov) => {
    cours.push({ name: gov.name, Code: gov.id });
  });
  const fetchCouriers = () => {
    AxiosController.get(`api/${slug}/${lang}/couriers/get-all-couriers/`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setCouriers(res.data);
      })
      .catch((e) => {});
  };

  const [state, setState] = useState({
    selection: {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  });
  const changeParam = () => {
    const courierIds = selectedCouriers
      .map((courier) => courier.Code)
      .join(",");
    urlParams.set("courier_id", courierIds);
    urlParams.set("product_id", product_id);
    urlParams.set("user_id", userId);
    urlParams.set("start_date", startDate);
    urlParams.set("end_date", endDate);
    Navigate(`?${urlParams.toString()}`);
  };
  const resetHandler = () => {
    handleCourier_id("");
    setSelectedCouriers([]);
    handleProduct_id("");
    handleUserId("");
    handleEndDate("");
    Navigate("");
  };
  const handleApply = (event, picker) => {
    const { startDate, endDate } = state.selection;
    const formattedStartDate = new Date(startDate.setHours(0, 0, 0, 0));
    const formattedEndDate = new Date(endDate.setHours(0, 0, 0, 0));

    const apiStartDate = format(formattedStartDate, "yyyy-MM-dd");
    const apiEndDate = format(formattedEndDate, "yyyy-MM-dd");
    handleStartDate(apiStartDate);
    handleEndDate(apiEndDate);
  };

  useEffect(() => {
    fetchCouriers();
  }, []);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        changeParam();
      }}
    >
      <div className="w-100 d-flex flex-column gap-1">
        <div className=" inputs-row d-flex w-100 gap-2 flex-column  flex-md-row">
          <Col lg={3} md={6}>
            <Form.Group
              className="mb-3 flex-grow-1"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Control
                type="text"
                placeholder={_("panel.employee")}
                onChange={(e) => {
                  handleUserId(e.target.value);
                }}
                value={userId}
              />
            </Form.Group>
          </Col>
          <Col lg={3} md={6}>
            <Form.Group
              className="mb-3 flex-grow-1"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Control
                type="text"
                placeholder={_("panel.product")}
                onChange={(e) => {
                  handleProduct_id(e.target.value);
                }}
                value={product_id}
              />
            </Form.Group>
          </Col>
          <Col lg={3} md={6}>
            <Form.Group className=" flex-grow-1 h-100">
              <div className="d-flex justify-content-center h-75">
                <MultiSelect
                  value={selectedCouriers}
                  onChange={(e) => {
                    setSelectedCouriers(e.target.value);
                  }}
                  options={cours}
                  optionLabel="name"
                  placeholder={_("panel.Courier")}
                  maxSelectedLabels={3}
                  className="w-100 align-items-center px-3 py-1"
                />
              </div>
            </Form.Group>
          </Col>
          <Col lg={3} md={6}>
            <DateRangePickerComponent
              state={state}
              setState={setState}
              applyDateRange={() => handleApply()}
            />{" "}
          </Col>
        </div>
      </div>
      <div className="action-inputs mt-3">
        <input
          className=" px-5 align-self-end py-2"
          style={{
            backgroundColor: "rgb(243, 168, 55)",
            border: "none",
            borderRadius: "7px",
            color: "white",
            textAlign: "center",
          }}
          onClick={() => {
            changeParam();
          }}
          type="submit"
          value={_("panel.search")}
        />
        <Button
          style={{ backgroundColor: "rgb(23, 43, 77)" }}
          variant="dark"
          className="d-inline"
          onClick={resetHandler}
        >
          {_("panel.reset")}
        </Button>
      </div>
    </form>
  );
};

export default Search;
