import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import Loader from "../../../loader/Loader";
import AxiosController from "../../../authentication/AxiosController";
import StatisticsSearchComponent from "./search components/StatisticsSearchComponent";
import { Button, Table } from "react-bootstrap";
import { Helmet } from "react-helmet";

const ProductsOverview = () => {
  const { slug, lang } = useParams();
  const { t } = useTranslation("global");
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [search, setSearch] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [productId, setProductId] = useState("");
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const getProductsOverview = () => {
    setLoading(true);
    AxiosController.get(`api/${slug}/${lang}/reports/products-overview`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        start_date: startDate,
        end_date: endDate,
        product_id: productId,
      }
   
    })
      .then((res) => {
        setLoading(false);
        setAllData(res?.data?.products || []);
      })
      .catch(() => setLoading(false));
  };
  useEffect(() => {
    setProductId(urlParams.get("product_id") || "");
    setStartDate(urlParams.get("start_date") || "");
    setEndDate(urlParams.get("end_date") || "");
  }, [urlParams]);

  useEffect(() => {
    getProductsOverview();
  }, [startDate, endDate, productId]);

  return (
    <div className="flex-grow-1 stats-component">
      <Helmet>
        <title>{t("panel.productsOverview")}</title>
      </Helmet>
      <Button
        className="fs-5 px-5 w-25 mb-4"
        style={{
          backgroundColor: "rgb(89, 198, 201)",
          border: "none",
          borderRadius: "7px",
        }}
        onClick={() => setSearch(!search)}
      >
        {t("panel.search")}
      </Button>
      {search && <StatisticsSearchComponent type={"product"} />}

      <div className="reports-container my-3 d-flex flex-column gap-2 px-2 position-relative">
        <Loader active={loading} />
        <div className="reports-header d-flex flex-column flex-md-row py-2 justify-content-between flex-wrap">
          <h3 className="fs-2 mb-4" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {t("panel.productsOverview")}
          </h3>
        </div>

        <div className="row" style={{ height: "1200px" }}>
          <div
            className="col-12"
            style={{ position: "relative", overflowX: "auto", height: "100%" }}
          >
            <Table>
              <thead>
                <tr className="text-center">
                  <th>{t("panel.productTitle")}</th>
                  <th>
                    {t("panel.total_orders")} , {t("panel.totalQuantity")}
                  </th>
                   <th>{t("panel.shippedOrders")}</th>
                  <th>{t("panel.soldOrders")}</th>
                  <th>{t("panel.sysCommission")}</th>
                  <th>{t("panel.sellerCommission")}</th>
                  <th>{t("panel.marketer_commission")}</th>
                </tr>
              </thead>
              <tbody>
                {allData.length > 0 &&
                  allData.map((item) => {
                    const totalOrders = Number(item?.total_orders_count);
                    const totalQuantity = Number(item?.total_quantity);
                    const shippedOrders = Number(item?.shipped_count);
                    const shippedQuantity = Number(item?.shipped_quantity);
                    const deliveredOrders = Number(
                      item?.delivered_cash_delivery_count
                    );
                    const deliveredQuantity = Number(
                      item?.delivered_cash_delivery_quantity
                    );

                    return (
                      <tr className="text-center" key={item?.id}>
                        <td>
                          <strong>#{item?.id}</strong>
                          <br />
                          {item?.title}
                          <br />
                          {Number(item?.stock_quantity).toLocaleString()} قطعة
                        </td>
                        <td>
                          {totalOrders.toLocaleString()}{" "}
                          {t("panel.orders_count")}
                          <br />
                          {totalQuantity.toLocaleString()}
                          {t("panel.orders_quantity")}
                        </td> 
                        <td>
                          ({((shippedOrders / totalOrders) * 100).toFixed(1)}%)
                          <br />( {shippedOrders.toLocaleString()})
                          {t("panel.orders_count")}
                          <br /> ({shippedQuantity.toLocaleString()})
                          {t("panel.orders_quantity")}
                        </td>  
                        <td>
                          {Number(deliveredOrders).toLocaleString() } 
                          {t("panel.orders_count")}
                          <br />({Number(deliveredQuantity).toLocaleString()})
                          {t("panel.orders_quantity")}
                          <br />
                          {((deliveredOrders / totalOrders) * 100).toFixed(
                            1
                          )}% {t("panel.fromTotal")}
                          <br />
                          {((deliveredOrders / shippedOrders) * 100).toFixed(1)}
                          % {t("panel.fromShipped")}
                        </td> 
                        <td>
                          {Number(item?.total_sys_commission).toLocaleString()}
                        </td>
                        <td>
                          #{item?.seller?.id} <br /> {item?.seller?.first_name}
                          {item?.seller?.last_name}
                          <br />
                          {Number(
                            item?.total_seller_commission
                          ).toLocaleString()}
                        </td>
                        <td>
                          {Number(
                            item?.total_marketer_commission
                          ).toLocaleString()}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsOverview;
