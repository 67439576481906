import React, { useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DateRangePickerComponent from "../../../../date/DateRangePickerComponent";
import AxiosController from "../../../../authentication/AxiosController";
import { MultiSelect } from "primereact/multiselect";
import { format } from "date-fns";

const SearchComponent = ({ type }) => {
  const [_] = useTranslation("global");
  const location = useLocation();
  const Navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);
  const [product_id, handleProduct_id] = useState("");
  const [startDate, handleStartDate] = useState("");
  const [endDate, handleEndDate] = useState("");

  const [state, setState] = useState({
    selection: {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  });
  const changeParam = () => {
    urlParams.set("product_id", product_id);
    urlParams.set("start_date", startDate);
    urlParams.set("end_date", endDate);
    Navigate(`?${urlParams.toString()}`);
  };
  const resetHandler = () => {
    handleProduct_id("");
    handleEndDate("");
    Navigate("");
  };
  const handleApply = (event, picker) => {
    const { startDate, endDate } = state.selection;
    const formattedStartDate = new Date(startDate.setHours(0, 0, 0, 0));
    const formattedEndDate = new Date(endDate.setHours(0, 0, 0, 0));

    const apiStartDate = format(formattedStartDate, "yyyy-MM-dd");
    const apiEndDate = format(formattedEndDate, "yyyy-MM-dd");
    handleStartDate(apiStartDate);
    handleEndDate(apiEndDate);
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        changeParam();
      }}
    >
      <div className="w-100 d-flex flex-column gap-1">
        <div className="row py-4 ">
          {type === "product" && (
            <>
              <Col md={6}>
                <Form.Group
                  className="mb-3 flex-grow-1"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    type="text"
                    placeholder={_("panel.product")}
                    onChange={(e) => {
                      handleProduct_id(e.target.value);
                    }}
                    value={product_id}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <DateRangePickerComponent
                  state={state}
                  setState={setState}
                  applyDateRange={() => handleApply()}
                />
              </Col>{" "}
            </>
          )}
        </div>
        <div className="action-inputs mt-3">
          <input
            className=" px-5 align-self-end py-2"
            style={{
              backgroundColor: "rgb(243, 168, 55)",
              border: "none",
              borderRadius: "7px",
              color: "white",
              textAlign: "center",
            }}
            onClick={() => {
              changeParam();
            }}
            type="submit"
            value={_("panel.search")}
          />
          <Button
            style={{ backgroundColor: "rgb(23, 43, 77)" }}
            variant="dark"
            className="d-inline"
            onClick={resetHandler}
          >
            {_("panel.reset")}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default SearchComponent;
