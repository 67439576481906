import React from "react";
import { useTranslation } from "react-i18next";

const TableLabels = ({stats}) => {
  const [_] = useTranslation("global");
  const role = localStorage.getItem("role");

  const roles_labels = {
    employee: [
      _("panel.product"),
      _("panel.totalorders"),
      _("panel.quantity"),
      _("panel.quantityWarehouse"),
      _("panel.leftAfterPick"),
      _("panel.themarketer"),
      _("panel.theseller"),

    ],
    admin: [
      _("panel.product"),
      _("panel.totalorders"),
      _("panel.quantity"),
      _("panel.quantityWarehouse"),
      _("panel.leftAfterPick"),
      _("panel.themarketer"),
      _("panel.theseller"),
      


    ],
    seller: [
      _("panel.product"),
      _("panel.totalorders"),
      _("panel.quantity"),
      _("panel.quantityWarehouse"),
      _("panel.leftAfterPick"),
      _("panel.themarketer"),
      _("panel.theseller"),

    ],
  };

  return (
    <tr>
    {roles_labels[role].map((label, index) => {
      return (
        <th key={index}>
          {label} {stats[index] ? `(${stats[index]})` : ""}
        </th>
      );
    })}
  </tr>
  
  );
};

export default TableLabels;
