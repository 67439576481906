import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";

import DoughnutChartDemo from "./PieChart";
import LineCharts from "./LineChart";
import Top from "./Top";
import { useParams } from "react-router-dom";
import AxiosController from "../../../authentication/AxiosController";
import AllStatusObj from "../../../authentication/AllStatusObj";
const data = [
  {
    title: "عدد الطلبات اليوم",
    value: 0,
  },
  {
    title: "طلبات تم شحنها اليوم",
    value: 0,
  },
  {
    title: "طلبات في البك اب",
    value: 0,
  },
  {
    title: "طلبات في المراجعة",
    value: 0,
  },
  { title: "طلبات في الشحن", value: 0 },
  {
    title: "عدد الطلبات الشهري",
    value: 0,
  },
  {
    title: "عدد الطلبات الموصلة شهريا",
    value: 0,
  },
  {
    title: "إجمالي المبيعات بالشحن",
    value: 0,
  },
  {
    title: "إجمالي المبيعات بدون شحن",
    value: 0,
  },
  {
    title: "إجمالي عدد الطلبات",
    value: 0,
  },
  {
    title: "الطلبات الموصلة",
    value: 0,
  },
  {
    title: "عدد المنتجات بالمخزن",
    value: 0,
  },
  { title: "القابل للسحب مسوقين", value: 0 },
  { title: "القابل للسحب تجار", value: 0 },
  { title: "رصيد مؤكد مسوقين", value: 0 },
  {
    title: "رصيد مؤكد تجار",
    value: 0,
  },
  { title: " رصيد متوقع مسوقين", value: 0 },
  {
    title: "رصيد متوقع تجار",
    value: 0,
  },
  {
    title: "ايرادات الشركة من الشحن",
    value: 0,
  },
  { title: "دعم الشركة للشحن	", value: 0 },
  {
    title: "ايرادات الشركة من المبيعات",
    value: 0,
  },
  { title: "إجمالي إيرادات الشركة", value: 0 },
  { title: " إجمالي المصاريف", value: 0 },
  { title: "الرصيد الحالي للشركة", value: 0 },
];

const CardItem = ({ item, index }) => {
  const { title, value, subtitle, subtitleValue } = item;
  const bg =
    index % 2 === 0 ? "rgba(235, 254, 255, 1)" : "rgba(255, 235, 205, 1)";
  
  return (
    <Col xs={12} sm={6} md={4} lg={2} xl={2} className="p-2">
    <Card
      className="shadow-sm p-1 rounded-4 border-0 d-flex flex-column h-100"
      style={{ background: bg, minHeight: "100px" }} // Ensure equal height
    >
      <Card.Body className="text-center p-1 d-flex flex-column justify-content-between">
        <Card.Title
          className="mb-1 fs-6 text-end pb-1"
          style={{ height: "20px" }}
        >
          {title}
        </Card.Title>
        <Card.Text className="fs-4 mt-3 fw-bold mb-0 flex-grow-1">
          {value}
        </Card.Text>
  
        <Card.Text className="fs-6 fw-normal d-flex justify-content-between align-items-center gap-1">
          <span>{subtitle && <span className="ms-2">{subtitle}</span>}</span>
          <span>{subtitleValue}</span>
        </Card.Text>
      </Card.Body>
    </Card>
  </Col>
  
  );
};
const AdminControlPanel = () => {
  const token = localStorage.getItem("token");
  const { slug, lang } = useParams();
  const currency = slug == 'ae' ? "درهم" : 'ريال';
  const [stats, setStats] = useState(data);

  const orders_stats = () => {
    AxiosController.get(`api/${slug}/${lang}/admin/orders-stats`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (res?.data?.today) {
          const total = res.data.today?.reduce((prev, record) => prev+record?.count, 0) ?? 0;
          const totalMonth = res.data.this_month?.reduce((prev, record) => prev+record?.count, 0) ?? 0;
          const shipped = res.data.today?.filter((record) => [AllStatusObj.SHIPPED].includes(record?.status)).reduce((prev, record) => prev+record?.count, 0) ?? 0;
          const deliveredMonthly = res.data.this_month	?.filter((record) => [AllStatusObj.CASH_DELIVERY, AllStatusObj.DELIVERED].includes(record?.status)).reduce((prev, record) => prev+record?.count, 0) ?? 0;
          const pickup = res.data?.total_pickup;
          const total_with_shipping = res.data?.total_with_shipping;
          const total_with_pending = res.data?.total_with_pending;
          const statsClone = [...stats];
          statsClone[0].value = `${Math.floor(total).toLocaleString()} اوردر`
          statsClone[1].value = `${Math.floor(shipped).toLocaleString()} اوردر`
          statsClone[2].value = `${Math.floor(pickup).toLocaleString()} اوردر`
          statsClone[3].value = `${Math.floor(total_with_pending).toLocaleString()} اوردر`
          statsClone[4].value = `${Math.floor(total_with_shipping).toLocaleString()} اوردر`
          statsClone[5].value = `${Math.floor(totalMonth).toLocaleString()} اوردر`
          statsClone[6].value = `${Math.floor(deliveredMonthly).toLocaleString()} اوردر`

          setStats(statsClone);
        }
      })
      .catch((e) => {});
  };
  const total_orders_stats = () => {
    AxiosController.get(`api/${slug}/${lang}/admin/total-orders-stats`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (res?.data?.overall_orders_stats	) {

          const totalDeliveredWithShipping = res.data.overall_orders_stats?.filter((record) => [AllStatusObj.CASH_DELIVERY, AllStatusObj.DELIVERED].includes(record?.status)).reduce((prev, record) => prev+record?.total_price, 0) ?? 0;
          const totalDeliveredWithoutShipping = res.data.overall_orders_stats?.filter((record) => [AllStatusObj.CASH_DELIVERY, AllStatusObj.DELIVERED].includes(record?.status)).reduce((prev, record) => prev+record?.net_price, 0) ?? 0;
          const total = res.data.overall_orders_stats?.reduce((prev, record) => prev+record?.count, 0) ?? 0;
          const totalDeliveredCount = res.data.overall_orders_stats?.filter((record) => [AllStatusObj.CASH_DELIVERY, AllStatusObj.DELIVERED].includes(record?.status)).reduce((prev, record) => prev+record?.count, 0) ?? 0;
          const warehouseStock = res.data.warehouse_stock ?? "0";
          const statsClone = [...stats];

          statsClone[7].value = `${Math.floor(totalDeliveredWithShipping).toLocaleString()} ${currency}`
          statsClone[8].value = `${Math.floor(totalDeliveredWithoutShipping).toLocaleString()} ${currency}`
          statsClone[9].value = `${Math.floor(total).toLocaleString()} اوردر`
          statsClone[10].value = `${Math.floor(totalDeliveredCount).toLocaleString()} اوردر`
          statsClone[11].value = `${parseInt(warehouseStock).toLocaleString()} منتج`
          setStats(statsClone);
        }
        
      })
      .catch((e) => {});
  };
  const system_revenue_loss = () => {
    AxiosController.get(`api/${slug}/${lang}/admin/system-revenue-loss`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (res.data?.shipping_revenue_loss) {
          const statsClone = [...stats];

          const revenue = res.data.shipping_revenue_loss.revenue ?? 0;
          const loss = (Math.abs(res.data?.shipping_revenue_loss?.loss) ?? 0) + (res.data?.handling_loss?.handling ?? 0);
          const commissions = res.data?.system_revenue?.sys_commission ?? 0;
          const expenses = res.data?.expenses ?? 0;

          statsClone[18].value = `${Math.floor(revenue).toLocaleString()} ${currency}`
          statsClone[19].value = `${Math.floor(loss).toLocaleString()} ${currency}`
          statsClone[20].value = `${Math.floor(commissions).toLocaleString()} ${currency}`
          statsClone[21].value = `${Math.floor(revenue+commissions).toLocaleString()} ${currency}`
          statsClone[22].value = `${Math.floor(expenses+loss).toLocaleString()} ${currency}`
          statsClone[23].value = `${Math.floor(revenue+commissions-expenses+loss).toLocaleString()} ${currency}`

          setStats(statsClone);
        }

      })
      .catch((e) => {});
  };
  const marketers_stats = () => {
    AxiosController.get(`api/${slug}/${lang}/get-marketers-stats-wallet/`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        const withdrawaleMarketers = res.data?.total_balances ?? 0;
        const confirmedMarketers = res.data?.total_confirmed ?? 0;
        const expectedMarketers = res.data?.total_expected ?? 0;
        const statsClone = [...stats];

        statsClone[12].value = `${Math.floor(withdrawaleMarketers).toLocaleString()} ${currency}`;
        statsClone[14].value = `${Math.floor(confirmedMarketers).toLocaleString()} ${currency}`;
        statsClone[16].value = `${Math.floor(expectedMarketers).toLocaleString()} ${currency}`;

        setStats(statsClone);

      })
      .catch((e) => {});
  };

  const sellers_stats = () => {
    AxiosController.get(`api/${slug}/${lang}/get-sellers-stats-wallet/`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        const withdrawaleMarketers = res.data?.total_balances ?? 0;
        const confirmedMarketers = res.data?.total_confirmed ?? 0;
        const expectedMarketers = res.data?.total_expected ?? 0;
        const statsClone = [...stats];

        statsClone[13].value = `${Math.floor(withdrawaleMarketers).toLocaleString()} ${currency}`;
        statsClone[15].value = `${Math.floor(confirmedMarketers).toLocaleString()} ${currency}`;
        statsClone[17].value = `${Math.floor(expectedMarketers).toLocaleString()} ${currency}`;

        setStats(statsClone);

      })
      .catch((e) => {});
  };

  useEffect(() => {
    orders_stats();
    total_orders_stats();
    system_revenue_loss();
    marketers_stats();
    sellers_stats();
  }, []);
  return (
    <Container fluid className="mt-4">
      <Row className=" g-4 gy-4 justify-content-center">
        {stats.map((item, index) => (
          <CardItem key={index + 1} index={index} item={item} />
        ))}
      </Row>

      <Row className="mt-5">
        {/* <Col md={5}>
          <DoughnutChartDemo />
        </Col> */}
        <Col md={12}>
          <LineCharts />
        </Col>
      </Row>
      <Row className="mt-5">
        <Top />
      </Row>
    </Container>
  );
};

export default AdminControlPanel;
