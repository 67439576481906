import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Pagination } from "react-bootstrap";
import AxiosController from "../../../authentication/AxiosController";
import AboutShippingTable from "./AboutShippingTable";
import Loader from "../../../loader/Loader";
import { Helmet } from "react-helmet";
import Search from "./Search";

const AboutShipping = () => {
  const { slug, lang } = useParams();
  const location = useLocation();
  const Navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);
  const [search, handleSearch] = useState(false);
  const token = localStorage.getItem("token");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [userId, setUserId] = useState("");
  const [courierId, setCourierId] = useState("");
  const [productId, setProductId] = useState("");
  const [_] = useTranslation("global");
  const [loading, setLoading] = useState(false);
  const [page, handlePage] = useState(parseInt(urlParams.get("page")) || 1);
  const [data, setData] = useState(null);

  const changeParam = (param, value) => {
    urlParams.set(param, value);
    Navigate(`?${urlParams.toString()}`);
  };

  const getAboutShipping = () => {
    setLoading(true);
    AxiosController.get(
      `api/${slug}/${lang}/orders/courier/report/cod/?page=${page}`,
      {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          start_date: startDate,
          end_date: endDate,
          product_id: productId,
          user_id: userId,
          courier_id:courierId
          
        },
      }
    )
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

 
  useEffect(() => {
    const nextPage = parseInt(urlParams.get("page"), 10) || 1; 
    handlePage(nextPage);
  }, [location.search, slug, lang]);

  useEffect(() => {
    const newUserId = urlParams.get("user_id") || "";
    const newCourierId = urlParams.get("courier_id") || "";
    const newProductId = urlParams.get("product_id") || "";
    const newStartDate = urlParams.get("start_date") || "";
    const newEndDate = urlParams.get("end_date") || "";
    setUserId(prev => (prev !== newUserId ? newUserId : prev));
    setCourierId(prev => (prev !== newCourierId ? newCourierId : prev));
    setProductId(prev => (prev !== newProductId ? newProductId : prev));
    setStartDate(prev => (prev !== newStartDate ? newStartDate : prev));
    setEndDate(prev => (prev !== newEndDate ? newEndDate : prev));
  }, [location.search]);

  useEffect(() => {
    getAboutShipping();
  }, [page, userId, courierId, productId, startDate, endDate]);
   

  return (
    <div
      className="position-relative tables-container"
      style={{ minHeight: "100px", width: "100%" }}
    >
      <Helmet>
        <title>{_("panel.aboutShipping")}</title>
      </Helmet>
      <Button
        className="fs-5 px-5 w-25 mb-4"
        style={{
          backgroundColor: "rgb(89, 198, 201)",
          border: "none",
          borderRadius: "7px",
        }}
        onClick={() => {
          handleSearch(!search);
        }}
      >
        {_("panel.search")}
      </Button>
      {search ? <Search /> : ""}
      <Loader active={loading} />
      <div
        className="tables-container d-flex flex-column px-2"
        style={{ position: "relative", flexGrow: 3 }}
      >
        <div
          className={`header d-flex gap-3 align-items-center justify-content-between`}
        >
          <h3 className="fs-1" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.aboutShipping")}
          </h3>
        </div>
        <div className="footer d-flex flex-column flex-grow-1">
          {data && data.data && <AboutShippingTable data={data.data} />}
        </div>
        {data && data.links && (
          <Pagination className="m-auto flex-wrap" dir="ltr">
            <Pagination.First onClick={() => changeParam("page", 1)} />
            <Pagination.Prev
              onClick={() => page > 1 && changeParam("page", page - 1)}
            />
            {data.links.map((element, index) => {
              if (index === 0 || index === data.links.length - 1) {
                return null;
              }
              return (
                <Pagination.Item
                  key={index}
                  active={element.active}
                  onClick={() =>
                    element.label !== "..." &&
                    changeParam("page", parseInt(element.label))
                  }
                >
                  {element.label}
                </Pagination.Item>
              );
            })}
            <Pagination.Next
              onClick={() =>
                page < data.last_page && changeParam("page", page + 1)
              }
            />
            <Pagination.Last
              onClick={() => changeParam("page", data.last_page)}
            />
          </Pagination>
        )}
      </div>
    </div>
  );
};

export default AboutShipping;
