import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";

import "../style.css";
import { useTranslation } from "react-i18next";
import Loader from "../../../loader/Loader";
import AxiosController from "../../../authentication/AxiosController";
import ProtectedSidebarRoute from "../../../authentication/ProtectedSidebarRoute";
import SellerStats from "./EarningsComponents/SellerStats";
import Roles from "../../../authentication/Roles";
import { currancyConvertor } from "../../../../utils/currancyConvertor";
import { Helmet } from "react-helmet";
import SearchComponent from "./search components/SearchComponent";
const report_detailed_products = (
  slug,
  lang,
  token,
  handleStatsWallet,
  handleAltName,
  handleLoader,
  startDate, 
  endDate,
  productId
) => {
  AxiosController.get(`api/${slug}/${lang}/get-reports-detailed-products/`, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      start_date: startDate,
      end_date: endDate,
      product_id: productId,
    }
  })
    .then((res) => {
      handleStatsWallet(res.data.data.data.length ? res.data.data.data : []);
      handleAltName(res.data.alt_name.split(" ")[0]);
      handleLoader(false);
    })
    .catch((e) => {
      handleLoader(false);
    });
};
const ReportsTable = () => {
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const [reportDetailedProducts, reportDetailedProductsHandler] = useState([]);
  const [alt_name, handleAltName] = useState("");
  const [loading, handleLoader] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [productId, setProductId] = useState("");
  const [search, setSearch] = useState(false);
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const [_] = useTranslation("global");
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  useEffect(() => {
    setProductId(urlParams.get("product_id") || "");
    setStartDate(urlParams.get("start_date") || "");
    setEndDate(urlParams.get("end_date") || "");
  }, [urlParams]);
  useEffect(() => {
    handleLoader(true);
    report_detailed_products(
      slug,
      lang,
      token,
      reportDetailedProductsHandler,
      handleAltName,
      handleLoader,
      startDate,
      endDate,
      productId
    );
  }, [slug, lang, token , startDate, endDate, productId]);

  return (
    <div className="position-relative" style={{ width: "100%" }}>
      <Helmet>
        <title>{_("panel.products")}</title>
      </Helmet>
      <Loader active={loading} />
      <ProtectedSidebarRoute
        component={SellerStats}
        role={role}
        roles={[Roles["SELLER"], Roles["FULFILLMENT"]]}
      />{" "}
      <Button
        className="fs-5 px-5 w-25 align-self-start"
        style={{
          backgroundColor: "rgb(89, 198, 201)",
          border: "none",
          borderRadius: "7px",
        }}
        onClick={() => {
          setSearch(!search);
        }}
      >
        {_("panel.search")}
      </Button>
      {search ? <SearchComponent type={"product"} /> : null}
      <div className="reports-header d-flex flex-column flex-md-row py-2 justify-content-between flex-wrap">
        <h3 style={{ color: "rgba(23, 43, 77, 1)" }}>
          {_("panel.productsOverview")}
        </h3>
      </div>
      <Table
        responsive
        dir={box_head_dir}
        className="reports-table text-nowrap text-center"
      >
        <thead>
          <tr>
            <th>{_("panel.productTitle")}</th>
            <th>{_("panel.totalorders")}</th>
            <th>{_("panel.totalAlreadyShippedorders")}</th>
            <th>{_("panel.totalSoldOrders")}</th>
            <th>{_("panel.Soldquantity")}</th>
            <th>{_("home.commission")}</th>
          </tr>
        </thead>
        <tbody>
          {reportDetailedProducts.length
            ? reportDetailedProducts.map((product) => {
                return (
                  <tr>
                    <td>
                      <span className="fw-bold"> #{product["p_id"]}</span>{" "}
                      <br /> {product["sku"]} <br /> {product["title"]}
                    </td>
                    <td>{product["total_orders"].toLocaleString()} </td>
                    <td>{product["total_shipped_orders"].toLocaleString() } <br/>% {(Number(product["total_shipped_orders"])/Number(product["total_orders"])).toLocaleString() *100} </td>
                    <td>
                      {product["total_sold_orders"]} <br/> (
                      {product["total_shipped_orders"]
                        ? Math.floor(
                            (product["total_sold_orders"] /
                              Number(product["total_shipped_orders"])) *
                              100
                          )
                        : 0}
                      %)
                    </td>
                    <td>{product["sold_quantity"]} </td>
                    <td>
                      {currancyConvertor(
                        (
                          (product["marketer_full_commission"] * 100) /
                          100
                        ).toLocaleString()
                      )}{" "}
                      {alt_name}{" "}
                    </td>
                  </tr>
                );
              })
            : ""}
        </tbody>
      </Table>
    </div>
  );
};

export default ReportsTable;
