import React, { useEffect, useState } from "react";
import IconsGen from "../../../icons_generator/IconsGen";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Loader from "../../../loader/Loader";
import AxiosController from "../../../authentication/AxiosController";
import { Table } from "react-bootstrap";
import { MultiSelect } from "primereact/multiselect";
import { Helmet } from "react-helmet";
import DateRangePickerComponent from "../../../date/DateRangePickerComponent";
import { format } from "date-fns";
import { Tabs } from "@mantine/core";

const Couriers = () => {
  const { slug, lang } = useParams();

  const [_] = useTranslation("global");
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [totalOrders, setTotalOrders] = useState(0);
  const [canceledTotal, seCanceledTotal] = useState([0, 0]);
  const [deliveredTotal, setDeliveredTotal] = useState([0, 0, 0, 0]);
  const [shippedTotal, setShippedTotal] = useState([0, 0, 0]);
  const [returnedTotal, setReturnedTotal] = useState([0, 0]);
  const [quantity, setQuantity] = useState(0);
  const [statsArray, SetStatsArray] = useState([0, 0, 0, 0]);
  const [couriers, setCouriers] = useState([]);
  const [grouped_stats_status, setGrouped_stats_status] = useState([]);
  const [grouped_stats_areas, setGrouped_stats_areas] = useState([]);
  const [grouped_stats_products, setGrouped_stats_products] = useState([]);
  const [productsTotalOrders, setProductsTotalOrders] = useState(1);
  const [productsTotalOrders2, setProductsTotalOrders2] = useState(0);
  const [productsTotalOrders3, setProductsTotalOrders3] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currentCourier, setCurrentCourier] = useState();

  const [selectedGovs, setSelectedGovs] = useState([]);
  const [state, setState] = useState({
    selection: {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  });
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const sortData = (key, data, setData) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...data].sort((a, b) => {
      if (typeof a[key] === "number") {
        return direction === "asc" ? a[key] - b[key] : b[key] - a[key];
      } else {
        return direction === "asc"
          ? a[key].localeCompare(b[key])
          : b[key].localeCompare(a[key]);
      }
    });

    setData(sortedData);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? " 🔼" : " 🔽";
    }
    return " ↕️";
  };

  const govs = [];

  couriers.forEach((gov) => {
    govs.push({ name: gov.name, Code: gov.id });
  });
  const status_grouped_stats = (courier_id, startDate, endDate) => {
    AxiosController.get(`api/${slug}/${lang}/get-courier-status-stats/`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        start_date: startDate,
        end_date: endDate,
        couriers: courier_id.map((courier) => courier.Code),
      },
    })
      .then((res) => {
        let total = res.data.reduce(
          (count, i) => count + Number(i["orders_count"]),
          0
        );
        let total_quantity = res.data.reduce(
          (count, i) => count + Number(i["total_quantity"]),
          0
        );

        setQuantity(total_quantity || 1);
        setTotalOrders(total || 1);
        setGrouped_stats_status(res.data);
        res.data.map((status_stat) => {
          if (
            status_stat["status"] === "Cash Delivery" ||
            status_stat["status"] === "Delivered"
          )
            SetStatsArray((arr) => {
              let tempArr = [...arr];
              tempArr[0] += (status_stat["orders_count"] / total) * 100;
              setDeliveredTotal((prev) => [
                prev[0] + Number(status_stat["orders_count"]),
                prev[1] + Number(status_stat["total_quantity"]),
                prev[2],
                prev[3],
              ]);
              return tempArr;
            });

          if (status_stat["status"] === "Delivered") {
            setDeliveredTotal((prev) => [
              prev[0],
              prev[1],
              prev[2] + Number(status_stat["value"]),
              prev[3],
            ]);
          }
          if (status_stat["status"] === "Cash Delivery") {
            setDeliveredTotal((prev) => [
              prev[0],
              prev[1],
              prev[2],
              prev[3] + Number(status_stat["value"]),
            ]);
          }
          if (
            status_stat["status"] === "Canceled" ||
            status_stat["status"] === "Pending Cancel"
          ) {
            SetStatsArray((arr) => {
              let tempArr = [...arr];
              tempArr[1] += (status_stat["orders_count"] / total) * 100;
              return tempArr;
            });

            seCanceledTotal((prev) => [
              prev[0] + Number(status_stat["orders_count"]),
              prev[1] + Number(status_stat["total_quantity"]),
            ]);
          }

          if (status_stat["status"] === "Pending Return")
            SetStatsArray((arr) => {
              let tempArr = arr;
              tempArr[2] += (status_stat["orders_count"] / total) * 100;
              setReturnedTotal((prev) => [
                prev[0] + Number(status_stat["orders_count"]),
                prev[1] + Number(status_stat["total_quantity"]),
              ]);
              return arr;
            });

          if (status_stat["status"] === "Shipped")
            SetStatsArray((arr) => {
              let tempArr = arr;
              tempArr[3] += (status_stat["orders_count"] / total) * 100;
              setShippedTotal((prev) => [
                prev[0] + Number(status_stat["orders_count"]),
                prev[1] + Number(status_stat["total_quantity"]),
                prev[2] + Number(status_stat["value"]),
              ]);
              return arr;
            });
        });
      })
      .catch((e) => {});
  };
  const area_grouped_stats = (courier_id, startDate, endDate) => {
    AxiosController.get(`api/${slug}/${lang}/get-courier-area-stats/`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        start_date: startDate,
        end_date: endDate,
        couriers: courier_id.map((courier) => courier.Code),
      },
    })
      .then((res) => {
        let totalAreaOrders = res.data.reduce((prv, prd_order) => {
          return prd_order["stats"] + prv;
        }, 0);
        setProductsTotalOrders3(totalAreaOrders);

        setGrouped_stats_areas(res.data);
      })
      .catch((e) => {});
  };
  const products_grouped_stats = (courier_id, startDate, endDate) => {
    console.log(startDate, endDate);

    AxiosController.get(`api/${slug}/${lang}/get-courier-product-stats/`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        start_date: startDate,
        end_date: endDate,
        couriers: courier_id.map((courier) => courier.Code),
      },
    })
      .then((res) => {
        let totalProductsOrders = res.data.reduce((prv, prd_order) => {
          return Number(prd_order["totalQ"]) + prv;
        }, 0);
        let totalProductsOrders2 = res.data.reduce((prv, prd_order) => {
          return Number(prd_order["deliveredQ"]) + prv;
        }, 0);
        setProductsTotalOrders(totalProductsOrders);
        setProductsTotalOrders2(totalProductsOrders2);
        setGrouped_stats_products(res.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  const get_couriers = () => {
    AxiosController.get(`api/${slug}/${lang}/couriers/get-all-couriers/`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setCouriers(res.data);
      })
      .catch((e) => {});
  };

  useEffect(() => {
    setLoading(true);
    get_couriers();
    setLoading(false);
  }, [slug, lang, token, setLoading]);
  const applyDateRange = () => {
    const { startDate, endDate } = state.selection;
    const formattedStartDate = new Date(startDate.setHours(0, 0, 0, 0));
    const formattedEndDate = new Date(endDate.setHours(0, 0, 0, 0));
    const apiStartDate = format(formattedStartDate, "yyyy-MM-dd");
    const apiEndDate = format(formattedEndDate, "yyyy-MM-dd");
    setStartDate(apiStartDate);
    setEndDate(apiEndDate);
    setLoading(true);
    setLoading(false);
  };
  return (
    <div className="flex-grow-1 stats-component">
      <Helmet>
        <title>{_("panel.couriers")}</title>
      </Helmet>
      <div className="reports-container my-3 d-flex flex-column gap-2 px-2 position-relative ">
        <Loader active={loading} />
        <div className="reports-header d-flex flex-column flex-md-row py-2 justify-content-between flex-wrap">
          <h3 className="fs-2" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.couriers")}
          </h3>
          <div className="interval d-flex flex-column gap-2 flex-md-row-reverse">
            <DateRangePickerComponent
              state={state}
              setState={setState}
              applyDateRange={() => applyDateRange()}
            />
          </div>
        </div>

        <div className="d-flex justify-content-center w-100 gap-2 h-100">
          <MultiSelect
            value={selectedGovs}
            onChange={(e) => {
              setSelectedGovs(e.value);
            }}
            options={govs}
            optionLabel="name"
            placeholder={_("panel.couriers")}
            maxSelectedLabels={3}
            className="w-100 align-items-center px-3 py-1"
          />
          <button
            className="btn px-3"
            style={{
              background: "rgba(89, 198, 201, 1)",
              color: "white",
            }}
            onClick={(e) => {
              setLoading(true);
              setProductsTotalOrders(1);
              setProductsTotalOrders2(1);
              setProductsTotalOrders3(1);
              status_grouped_stats(selectedGovs, startDate, endDate);
              area_grouped_stats(selectedGovs, startDate, endDate);
              products_grouped_stats(selectedGovs, startDate, endDate);
              setCurrentCourier(selectedGovs);
              SetStatsArray([0, 0, 0, 0]);
              seCanceledTotal([0, 0]);
              setDeliveredTotal([0, 0, 0, 0]);
              setShippedTotal([0, 0, 0]);
              setReturnedTotal([0, 0]);
              // setLoading(false);
            }}
          >
            {_("panel.show")}
          </button>
        </div>
      </div>
      <Tabs defaultValue="totalorders" className="w-100">
        <Tabs.List>
          <Tabs.Tab
            value="totalorders"
            className="fs-5"
            color="rgb(89, 198, 201)"
          >
            {_("panel.totalorders")}
          </Tabs.Tab>
          <Tabs.Tab
            value="statusDetails"
            className="fs-5"
            color="rgb(89, 198, 201)"
          >
            {_("panel.statusDetails")}
          </Tabs.Tab>

          <Tabs.Tab value="cities" className="fs-5" color="rgb(89, 198, 201)">
            {_("home.cities")}
          </Tabs.Tab>
          <Tabs.Tab value="products" className="fs-5" color="rgb(89, 198, 201)">
            {_("panel.products")}
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="totalorders">
          <div
            className="boxes-container d-flex flex-wrap gap-2 mt-4"
            // style={{ direction: "rtl" }}
            dir={box_head_dir}
          >
            <div className="report-box col-12 col-lg-2 col-md-5 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3 orangify">
              <div className="top d-flex align-items-center gap-2">
                <IconsGen icon_name={"orangdash"} />
                <p className="flex-grow-1">{_("panel.orders")} </p>
              </div>
              <div className="middle fs-5">
                <p>
                  {Number(totalOrders).toLocaleString()}{" "}
                  {_("panel.orders_count")}{" "}
                </p>
                <p>
                  {" "}
                  {Number(quantity).toLocaleString()}{" "}
                  {_("panel.orders_quantity")}
                </p>
              </div>
            </div>
            <div className="report-box col-12 col-lg-2 col-md-5 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3">
              <div className="top d-flex align-items-center gap-2">
                <IconsGen icon_name={"dollarSign"} />
                <p className="flex-grow-1">{_("panel.نسبة التسليمات")}</p>
              </div>
              <div className="middle fs-5">
                <p>
                  {Number(deliveredTotal[0]).toLocaleString()}{" "}
                  {_("panel.orders_count")}
                </p>
                <p>
                  {Number(deliveredTotal[1]).toLocaleString()}{" "}
                  {_("panel.orders_quantity")}
                </p>
                <p>
                  {statsArray[0].toLocaleString()} {"%"} <br />
                </p>
              </div>
            </div>
            <div className="report-box col-12 col-lg-2 col-md-5 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3 orangify">
              <div className="top d-flex align-items-center gap-2">
                <IconsGen icon_name={"orangdash"} />
                <p className="flex-grow-1">{_("panel.نسبة الإلغاءات")}</p>
              </div>
              <div className="middle fs-5">
                <p>
                  {Number(canceledTotal[0]).toLocaleString()}{" "}
                  {_("panel.orders_count")}
                </p>
                <p>
                  {Number(canceledTotal[1]).toLocaleString()}{" "}
                  {_("panel.orders_quantity")}
                </p>
                <p>
                  {statsArray[1].toLocaleString()}
                  {"%"}
                </p>
              </div>
            </div>
            <div className="report-box col-12 col-lg-2 col-md-5 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3">
              <div className="top d-flex align-items-center gap-2">
                <IconsGen icon_name={"dollarSign"} />
                <p className="flex-grow-1">{_("panel.نسبة المرتجعات")}</p>
              </div>
              <div className="middle fs-5">
                <p>
                  {Number(returnedTotal[0]).toLocaleString()}{" "}
                  {_("panel.orders_count")}
                </p>
                <p>
                  {Number(returnedTotal[1]).toLocaleString()}{" "}
                  {_("panel.orders_quantity")}
                </p>
                <p>
                  {statsArray[2].toLocaleString()}

                  {"%"}
                </p>
              </div>
            </div>

            <div className="report-box col-12 col-lg-2 col-md-5 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3 orangify">
              <div className="top d-flex align-items-center gap-2">
                <IconsGen icon_name={"orangdash"} />

                <p className="flex-grow-1">{_("panel.في الشحن")}</p>
              </div>
              <div className="middle fs-5">
                <p>
                  {Number(shippedTotal[0]).toLocaleString()}{" "}
                  {_("panel.orders_count")}
                </p>
                <p>
                  {Number(shippedTotal[1]).toLocaleString()}{" "}
                  {_("panel.orders_quantity")}
                </p>
                <p>
                  {statsArray[3].toLocaleString()}

                  {"%"}
                </p>
              </div>
            </div>

            <div className="report-box col-12 col-lg-2 col-md-5 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3">
              <div className="top d-flex align-items-center gap-2">
                <IconsGen icon_name={"dollarSign"} />
                <p className="flex-grow-1">{_("panel.expected_collection")}</p>
              </div>
              <div className="middle fs-5">
                <p>{Number(shippedTotal[2]).toLocaleString()}</p>
              </div>
            </div>

            <div className="report-box col-12 col-lg-2 col-md-5 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3 orangify">
              <div className="top d-flex align-items-center gap-2">
                <IconsGen icon_name={"orangdash"} />

                <p className="flex-grow-1">{_("panel.collected_amount")}</p>
              </div>
              <div className="middle fs-5">
                <p>{Number(deliveredTotal[3]).toLocaleString()}</p>
              </div>
            </div>
            <div className="report-box col-12 col-lg-2 col-md-5 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3">
              <div className="top d-flex align-items-center gap-2">
                <IconsGen icon_name={"dollarSign"} />

                <p className="flex-grow-1">{_("panel.deliveredCollected")}</p>
              </div>
              <div className="middle fs-5">
                <p>{Number(deliveredTotal[2]).toLocaleString()}</p>
              </div>
            </div>

            <div className="report-box col-12 col-lg-2 col-md-5 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3 orangify">
              <div className="top d-flex align-items-center gap-2">
                <IconsGen icon_name={"orangdash"} />

                <p className="flex-grow-1"> {_("panel.total_amount")}</p>
              </div>
              <div className="middle fs-5">
                <p>
                  {(
                    Number(deliveredTotal[2]) + Number(deliveredTotal[3])
                  ).toLocaleString()}
                </p>
              </div>
            </div>
          </div>
        </Tabs.Panel>

        <Tabs.Panel value="statusDetails">
          <Table
            responsive
            dir={box_head_dir}
            className="reports-table text-nowrap text-center mt-4"
          >
            <thead>
              <tr>
                <th>{_("panel.status")}</th>
                <th>
                  {_("panel.totalorders2")} ({totalOrders.toLocaleString()})
                </th>
                <th>{_("panel.value")}</th>
                <th>{_("panel.shippingCostsValue")}</th>
              </tr>
            </thead>
            <tbody>
              {grouped_stats_status.map((stat) => {
                return (
                  <tr>
                    <td>{_(`panel.${stat["status"]}`)}</td>
                    <td>
                      {stat["orders_count"]} (
                      {(
                        (stat["orders_count"] / totalOrders) *
                        100
                      ).toLocaleString()}
                      %)
                    </td>
                    <td>{stat["value"].toLocaleString()}</td>
                    <td>{stat["shipping_cost"].toLocaleString()}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Tabs.Panel>

        <Tabs.Panel value="cities">
          <Table
            responsive
            dir={box_head_dir}
            className="reports-table text-nowrap text-center"
          >
            <thead>
              <tr>
                <th
                  onClick={() =>
                    sortData(
                      "arabic_name",
                      grouped_stats_areas,
                      setGrouped_stats_areas
                    )
                  }
                >
                  {_("panel.gov")} {getSortIcon("arabic_name")}
                </th>
                <th
                  onClick={() =>
                    sortData(
                      "shipped_order_price",
                      grouped_stats_areas,
                      setGrouped_stats_areas
                    )
                  }
                >
                  {_("panel.shipped_orders")} (المبلغ){" "}
                  {getSortIcon("shipped_order_price")}
                </th>
                <th
                  onClick={() =>
                    sortData(
                      "delivered_orders",
                      grouped_stats_areas,
                      setGrouped_stats_areas
                    )
                  }
                >
                  {_("panel.deliveredOrders")} {getSortIcon("delivered_orders")}
                </th>
                <th
                  onClick={() =>
                    sortData(
                      "shipped_orders",
                      grouped_stats_areas,
                      setGrouped_stats_areas
                    )
                  }
                >
                  {_("panel.shippedorders")} {getSortIcon("shipped_orders")}
                </th>
                <th
                  onClick={() =>
                    sortData(
                      "canceled_orders",
                      grouped_stats_areas,
                      setGrouped_stats_areas
                    )
                  }
                >
                  {_("panel.canceledOrders")} {getSortIcon("canceled_orders")}
                </th>
              </tr>
            </thead>
            <tbody>
              {grouped_stats_areas.map((stat) => (
                <tr key={stat["arabic_name"]}>
                  <td>{stat["arabic_name"]}</td>
                  <td>
                    {Number(stat["shipped_order_price"]).toLocaleString()}{" "}
                    <br />({Number(stat["shipped_orders"]).toLocaleString()}){" "}
                    {_("panel.orders_count")}
                  </td>
                  <td>
                    {Number(stat["delivered_order_price"]).toLocaleString()}{" "}
                    <br />({Number(stat["delivered_orders"]).toLocaleString()}){" "}
                    {_("panel.orders_count")} <br />(
                    {(
                      (Number(stat["delivered_orders"]) /
                        Number(stat["total_orders"])) *
                      100
                    ).toLocaleString()}{" "}
                    %)
                  </td>
                  <td>{stat["shipped_orders"]}</td>
                  <td>
                    {Number(stat["canceled_order_price"]).toLocaleString()}
                    <br />({Number(
                      stat["canceled_orders"]
                    ).toLocaleString()}) {_("panel.orders_count")} <br />(
                    {(
                      (Number(stat["canceled_orders"]) /
                        Number(stat["total_orders"])) *
                      100
                    ).toLocaleString()}{" "}
                    %)
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Tabs.Panel>

        <Tabs.Panel value="products">
          <Table
            responsive
            dir={box_head_dir}
            className="reports-table text-nowrap text-center mt-4"
          >
            <thead>
              <tr>
                <th
                  onClick={() =>
                    sortData(
                      "product.id",
                      grouped_stats_products,
                      setGrouped_stats_products
                    )
                  }
                >
                  {_("panel.title")} {getSortIcon("product.id")} <br /> &nbsp;
                </th>
                <th
                  onClick={() => {
                    sortData(
                      "totalOrders",
                      grouped_stats_products,
                      setGrouped_stats_products
                    ); 
                  }}
                >
                  {_("panel.totalorders2")} {getSortIcon("totalOrders")} <br />(
                  {totalOrders.toLocaleString()}) <br />
                  {_("panel.totalQ")}  <br />(
                  {productsTotalOrders.toLocaleString()})
                </th>

                <th
                  onClick={() =>
                    sortData(
                      "deliveredOrders",
                      grouped_stats_products,
                      setGrouped_stats_products
                    )
                  }
                >
                  {_("panel.deliveredOrders")} {getSortIcon("deliveredOrders")}{" "}
                  <br /> (
                  {Math.round(
                    (statsArray[0] / 100) * totalOrders
                  ).toLocaleString()}
                  ) (
                  {Number(
                    (Math.round((statsArray[0] / 100) * totalOrders) /
                      totalOrders) *
                      100
                  ).toLocaleString()}{" "}
                  %)
                </th>
                <th
                  onClick={() =>
                    sortData(
                      "deliveredQ",
                      grouped_stats_products,
                      setGrouped_stats_products
                    )
                  }
                >
                  {_("panel.totalDeliveredQ")} {getSortIcon("deliveredQ")}{" "}
                  <br /> ({productsTotalOrders2.toLocaleString()})
                </th>
                <th
                  onClick={() =>
                    sortData(
                      "totalShippedOrders",
                      grouped_stats_products,
                      setGrouped_stats_products
                    )
                  }
                >
                  {_("panel.shippedorders")} {getSortIcon("totalShippedOrders")}{" "}
                  <br /> &nbsp;
                </th>
              </tr>
            </thead>
            <tbody>
              {grouped_stats_products.map((stat) => (
                <tr key={stat["product"]["id"]}>
                  <td>
                    <span className="fw-bold"> #{stat["product"]["id"]}</span>{" "}
                    <br /> {stat["product"]["title"]}
                  </td>
                  <td>{stat["totalOrders"].toLocaleString()} طلب <br/> {stat["totalQ"].toLocaleString()} قطعة</td>
                   <td>
                    {stat["deliveredOrders"]} <br /> (
                    {Number(
                      (stat["deliveredOrders"] / stat["totalOrders"]) * 100
                    ).toLocaleString()}
                    %)
                  </td>
                  <td>{stat["deliveredQ"].toLocaleString()}</td>
                  <td>{stat["totalShippedOrders"].toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};
export default Couriers;
