import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";

import TableLabels from "./TableLabels";
import IconsGen from "../../../../icons_generator/IconsGen";
const colors = {
  seller: "rgba(89, 198, 201, 1)",
  affiliate: "rgb(243, 168, 55)",
  employee: "rgba(23, 43, 77, 1)",
};
const PaymentRequestsTableRow = ({
  payments_list,
  alt_name,
  handleLoader,
  counter,
}) => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const role = localStorage.getItem("role");
  let totalPriceSysLoop = 0,
    totalPriceCourLoop = 0;
  const [totalPriceSys, setTotalPriceSys] = useState(0);
  const [totalPriceCour, setTotalPriceCour] = useState(0);
  useEffect(() => {
    // excelData.forEach((item) => {
    //   setFormattedData({...formatted_data, [item['track']]: item['cost']})
    // });
    payments_list.data[0].forEach((order, index) => {
      totalPriceSysLoop += parseInt(order["total_quantity"]);
      totalPriceCourLoop += order["left"];
    });
    setTotalPriceSys(totalPriceSysLoop);
    setTotalPriceCour(totalPriceCourLoop);
  }, [payments_list]);
  return (
    <div
      className={`table-responsive my-3 d-flex ${
        lang === "en" ? "flex-row" : "flex-row-reversed"
      } align-items-center`}
    >
      <table
        className="table table-borderless text-nowrap"
        htmlFor="flexCheckDefault"
      >
        <thead>
          <TableLabels
            stats={[
              payments_list.data[0].length,
              counter.reduce((prv, c) => c.count + prv, 0),
              totalPriceSys,
              totalPriceCour,
            ]}
          />
        </thead>
        <tbody>
          {payments_list.data[0].map((order, index) => {
            const showedMarketers = [];
            return (
              <tr className="" key={index}>
                <td className="align-middle">
                  <img width={"70px"} src={order["thumbnail"]} /> <br />
                  {order["title"]} <br /> {order["sku"]} <br />
                  {order["color"] ? (
                    <>
                      {order["color"]}
                      <br />
                    </>
                  ) : (
                    <> {"_"} </>
                  )}
                  {order["size"] || order["Size"] ? (
                    <>
                      {order["Size"] || order["size"]}
                      <br />
                    </>
                  ) : (
                    <>
                      {" "}
                      {"_"} <br />{" "}
                    </>
                  )}
                  <NavLink
                    style={{ color: "rgb(243, 168, 55)" }}
                    to={`/${slug}/${lang}/${
                      role === "admin"
                        ? `panel/products/?code=${order["product_id"]}`
                        : ""
                    }`}
                  >
                    #{order["product_id"]}
                  </NavLink>
                </td>
                <td className="align-middle">{order["order_number"]}</td>
                <td className="align-middle">{order["total_quantity"]}</td>
                <td className="align-middle">{order["left"] || 0}</td>

                <td className="align-middle">
                  {(order["left"] || 0) - order["total_quantity"]}
                </td>

                <td className="align-middle">
                  {payments_list.data[1]
                    ? payments_list.data[1].map((marketer) => {
                        if (
                          !showedMarketers.includes(marketer["marketer_id"]) &&
                          order["product_id"] == marketer["product_id"]
                        ) {
                          showedMarketers.push(marketer["marketer_id"]);
                          return (
                            <>
                              {marketer["first_name"]} {marketer["last_name"]} #
                              {marketer["marketer_id"]}
                              <br />
                            </>
                          );
                        } else {
                          return;
                        }
                      })
                    : ""}
                </td>
                <td className="align-middle">
                  #{order["seller_id"]} <br />
                  {order["first_name"]}
                  {order["last_name"]}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default PaymentRequestsTableRow;
